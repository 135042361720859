
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

  import { Crud } from '@/ts/enums/crud'
  import { LookupState } from '@/ts/states/lookup/lookupState'
  import { Person } from '@/ts/models/person'
  import { Verification } from '@/ts/models/verification'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import BMergeDateRow from '@/components/tables/BMergeDateRow.vue'
  import BMergeHashRow from '@/components/tables/BMergeHashRow.vue'
  import BMergeInputRow from '@/components/tables/BMergeInputRow.vue'
  import BMergeSelectRow from '@/components/tables/BMergeSelectRow.vue'
  import FingerPin from '@/components/badges/FingerPin.vue'
  import ImageView from '@/components/images/ImageView.vue'
  import { EventBus } from '@/ts/event-bus'
  import { isEqual } from 'date-fns'
  import _ from 'lodash'
  import { bool } from 'aws-sdk/clients/redshiftdata'
import { PersonState } from '../../../ts/states/person/personState'

  @Component({
    components: {
      BMergeDateRow,
      BMergeHashRow,
      BMergeInputRow,
      BMergeSelectRow,
      FingerPin,
      ImageView
    }
  })
  export default class DuplicateDetails extends Vue {

    /** PROPERTIES */

    @Prop({ default: new Verification() })
    public verification!: Verification

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus


/** PRIVATE PROPERTIES */

    protected duplicate = new Person()
    protected original = new Person()
    protected reset = new Person()

    protected checked = false
    protected dateOfBirth = this.original.dateOfBirth
    protected lookupState = new LookupState(this.$store)
    protected personState = new PersonState(this.$store)
    protected idNumber = ''
    protected mode = Crud.UPDATE

    protected selected = Array(13).fill(false)

    /** LIFECYCLE */

    public created() {
    
      this.status = ViewStatus.NONE
      EventBus.$on('cropped', this.onReceive)   
    }

    public beforeDestroy() {
      EventBus.$off('cropped', this.onReceive)
    }


    /** OBSERVERS */

    @Watch('checked', { immediate: true, deep: false })
    protected onCheckedChanged(selected: bool) {
      this.selected = Array(13).fill(selected)
    }

    @Watch('verification', { immediate: true, deep: false })
    protected onVerificationChanged(verification: Verification) {
      this.duplicate = verification.duplicate!
      this.original = verification.original
      this.reset = verification.original.copy()
      this.checked = false
      this.selected = Array(13).fill(true)
      this.selected = Array(13).fill(false)
    }

    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    /** EVENTS */

    protected onView() {
      this.$emit('view', this.duplicate)
    }

    protected async onReceive() {
      this.$bvModal.hide('edit-image')
    }


    /** PRIVATE METHODS */

    protected isEqual(lhs: Date, rhs: Date) {
      return (rhs == null) ? false : isEqual(lhs, rhs)
    }
  }

