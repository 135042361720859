
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Person } from '@/ts/models/person'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import pluralize from 'pluralize'

  import AsyncCarousel from '@/components/carousels/AsyncCarousel.vue'
  import PersonSlide from '@/components/carousels/slides/PersonSlide.vue'

  @Component({
    components: {
      AsyncCarousel,
      PersonSlide
    }
  })
  export default class DuplicatesTab extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: ToolboxState


    @Prop({ default: true })
    public visible!: boolean


    /** PRIVATE PROPERIES  */

    private authState = new AuthState(this.$store)



    /** COMPUTED PROPERTIES */

    protected get canViewProfile() {
      return this.authState.user.hasPermission(Claims.PROFILE, Crud.READ)
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get verification() {
      return this.state.verification
    }

    protected get persons() {
      return this.state.duplicates
    }

    protected get slidesToShow() {
      return this.state.slidesToShow
    }

    protected get status() {
      return this.state.status
    }
    protected get title() {
      return (this.isUpdating) ? `Searching for potential duplicates...` : `Showing ${pluralize('potential duplicate', this.persons.length, true)}`
    }

    protected get totalSlides() {
      return this.persons.length
    }


    /** EVENTS */

    protected onChange(checked: boolean, person: Person) {
      if (checked) {
        this.$emit('change', person)
      }
    }

    protected onClick(person: Person) {
      this.$emit('click', person)
    }
  }

