
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { LookupState } from '@/ts/states/lookup/lookupState'
  import { QueryParams } from '@/ts/api/queryParams'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import { DateFormatter } from '@/ts/formatters/dateFormatter'
  import MultiSelect from '@/components/multiselect/MultiSelect.vue'

  @Component({
    components: { MultiSelect }
  })
  export default class VerifySearchForm extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: ToolboxState

    @Prop({ default: false })
    public visible!: boolean

    /** PRIVATE PROPERTIES */

    protected lookupState = new LookupState(this.$store)
    protected queryParams = this.state.queryParams


    /** OBSERVERS */

    @Watch('queryParams.states.length')
    protected onStatesChanged(old: number, now: number) {
      if (old != now) {
        const states = this.queryParams.states?.map(s => s.name)
        this.queryParams.venues = this.queryParams.venues?.filter(v => states?.includes(v.state))
      }
    }

    
    @Watch('state.queryParams')
    protected onQueryParamsChanged(queryParams: QueryParams) {
      this.queryParams = queryParams
    }


    /** COMPUTED PROPERTIES */

    protected get currentDateStandardlization() {
      return DateFormatter.currentDateStandardlization();
    }

    protected get searchText() {
      return (this.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Update Search'
    }

    protected get status() {
      return this.state.status
    }

    protected get venues() {
      if (this.queryParams.states != null) {
        return this.state.organisations?.filter(o => this.queryParams.states!.map(s => s.name).includes(o.state)).sort((a, b) => a.name.localeCompare(b.name))
      }

      return []
    }


    /** EVENTS */

    protected onReset() {
      this.state.reset()
    }

    protected async onSubmit() {
      this.state.clearResults()
      this.queryParams.currentPage = 1
      await this.state.fetch(this.queryParams)
    }
  }

