
  import { Component, Vue } from 'vue-property-decorator'

  import { PoliceReportsState } from '@/ts/states/toolbox/children/policeReportsState'
  import { PoliceReport } from '@/ts/models/policeReport'
  import { PoliceReportStatus } from '@/ts/enums/policeReportStatus'
  import { Route } from '@/ts/models/route'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import HeadPanel from '@/components/panels/HeadPanel.vue'
  import PoliceReportPdf from './components/PoliceReportPdf.vue'

  @Component({
    components: {
      HeadPanel,
      PoliceReportPdf
    }
  })
  export default class PoliceReports extends Vue {

    /** PRIVATE PROPERTIES */

    protected returnRoute?: Route | null

    private state = new PoliceReportsState(this.$store)


    /** LIFECYCLE */

    public created() {
      this.returnRoute = this.state.popRoute()
      this.state.fetch()
    }


    /** COMPUTED PROPERTIES */

    protected get policeReports() {
      return this.state.policeReports
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isNone() {
      return this.status == ViewStatus.NONE
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return (this.status > ViewStatus.IN_PROGRESS && this.status < ViewStatus.FAILED) || (this.status == ViewStatus.NONE)
    }

    protected get isArchived() {
      return (this.state.mode == PoliceReportStatus.ARCHIVED) ? false : true
    }

    protected get queryParams() {
      return this.state.queryParams
    }

    protected get status() {
      return this.state.status
    }

    protected icon(statusId: PoliceReportStatus) {
      return (statusId == PoliceReportStatus.ACCEPTED) ? 'hand-thumbs-up-fill' : 'hand-thumbs-down-fill'
    }

    protected variant(statusId: PoliceReportStatus) {
      return (statusId == PoliceReportStatus.ACCEPTED) ? 'success' : 'danger'
    }

    protected customArchivedClass(policeReport: PoliceReport) {
      if (this.state.mode == PoliceReportStatus.ARCHIVED) {
        return (policeReport.statusId == PoliceReportStatus.ACCEPTED) ? 'cursor-pointer' : 'pr-archived'
      }
      else {
        return 'cursor-pointer'
      }

    }

    /** EVENTS */

    protected onPageChanged(page: number) {
      this.state.paginate(page)
    }

    protected onRowClicked(policeReport: PoliceReport) {
      if (policeReport.statusId == PoliceReportStatus.ACCEPTED || policeReport.statusId == PoliceReportStatus.REQUESTED) {
        this.state.setPoliceReport(policeReport)
        this.$bvModal.show('police-report-pdf')
      }
    }

    protected onSortChanged(context: ISortContext) {
      this.state.sort(context)
    }
  }

