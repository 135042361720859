
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { PoliceReportsState } from '@/ts/states/toolbox/children/policeReportsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import { ValidationObserver } from 'vee-validate'
  import { IValidationState } from '@/ts/vee-validate/'

  @Component({
  })
  export default class DenyForm extends Vue {

    /** PUBLIC PROPERTIES */

    public $refs!: {
      vue: Vue
      element: HTMLInputElement

      vues: Vue[]
      elements: HTMLInputElement[]

      form: HTMLFormElement

      observer: InstanceType<typeof ValidationObserver>
    }

    @Prop({ default: 'You have unsaved changes which will be lost' })
    public cancelMessage!: string

    @Prop()
    public id!: string

    @Prop()
    public state!: PoliceReportsState


    /** PRIVATE PROPERTIES */

    protected showModal = false
    private comment = ''

    /** OBSERVERS */
    /** COMPUTED PROPERTIES */

    protected get isFailed() {
      return this.state.status == ViewStatus.FAILED
    }

    protected get title() {
      return 'Add new reason for rejecting a request '
    }

    protected get addMessage() {
      return (this.state.status == ViewStatus.IN_PROGRESS) ? 'Sending...' : 'Send'
    }

    protected onHidden() {
      this.showModal = false
      this.onReset()
    }

    /** EVENTS */

    protected onCancel() {
      if (this.showModal) {
        this.$bvModal.msgBoxConfirm(this.cancelMessage, { title: 'Are you sure?' })
          .then(confirmed => {
            if (confirmed) {
              this.showModal = false
              this.$emit('cancel')
            }
          })
          .catch(err => {
            // An error occurred
          })
      }
      else {
        this.showModal = false
        this.$emit('cancel')
      }
    }


    protected onReset() {

      const form = this.$refs.form as HTMLFormElement
      form?.reset()
      this.comment = ''
      this.$nextTick(() => {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
        observer?.reset()
      })
    }

    protected async addComment() {
      const validator = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await validator.validate()  
      if (isValid) {
        this.showModal = this.isFailed
        this.$emit('add', this.comment)
      }
    }

    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null;
    }

  }

