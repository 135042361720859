
  import { Component, Prop, Vue } from 'vue-property-decorator'

  
  @Component
  export default class DuplicateToast extends Vue {

    /** PROPERTIES */

    @Prop()
    public duplicates!: number
  }


