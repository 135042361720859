
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { Crud } from '@/ts/enums/crud'
  import { PersonState } from '@/ts/states/person/personState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import PersonDetails from '@/components/person/PersonDetails.vue'
  import PersonNavigation from '@/components/navigation/PersonNavigation.vue'

  @Component({
    components: {
      PersonDetails,
      PersonNavigation
    }
  })
  export default class PersonProfileView extends Vue {

    /** PROPERTIES */

    @Prop({ default: Crud.UPDATE })
    public eventMode!: Crud

    @Prop({ default: Crud.UPDATE })
    public mode!: Crud

    @Prop({ default: Crud.UPDATE })
    public imageMode!: Crud

    @Prop()
    public state!: PersonState

    /** COMPUTED PROPERTIES */

    protected get isSuccessful() {
      return this.state.status == ViewStatus.SUCCEEDED
    }

    /** EVENTS */

    protected onIdQuery() {
      this.$emit('query')
    }
  }

