
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component
  export default class BMergeDateRow extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: false })
    public checked!: boolean

    @Prop()
    public label!: Date

    @Prop()
    public initialValue!: Date

    @Prop()
    public resetValue!: Date

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop({ default: '150px' })
    public width!: string

    @Prop()
    public value!: string


    /** PRIVATE PROPERTIES */

    protected isChecked = false
    protected inputValue = DateFormatter.format(this.initialValue, DateStyle.Picker)


    /** OBSERVERS */

    @Watch('checked', { immediate: true})
    protected onCheckedChanged(checked: boolean) {
      this.isChecked = checked
    }

    @Watch('initialValue', { immediate: true })
    protected onInitialValueChanged(value: Date) {
      this.inputValue = DateFormatter.format(value, DateStyle.Picker)
    }

    @Watch('isChecked')
    protected onIsCheckedChanged(checked: boolean) {
      this.$emit('input', (checked) ? DateFormatter.date(this.inputValue, DateStyle.Picker) : this.resetValue)
    }

    @Watch('resetValue', { immediate: false })
    protected onResetValueChanged() {
      this.isChecked = false
    }

    @Watch('status')
    protected onStatusChanged(status: ViewStatus) {
      if (status == ViewStatus.IN_PROGRESS) {
        this.isChecked = false
      }
    }

    
    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    /** EVENTS */

    protected onInput(event: InputEvent) {
      this.inputValue = (event.target! as any).value
      if (this.isChecked) {
        this.$emit('input', DateFormatter.date(this.inputValue, DateStyle.Picker))
      }
    }
  }
 
