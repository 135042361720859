
  import { Component, Watch, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { QueryParams } from '@/ts/api/queryParams'
  import { Route } from '@/ts/models/route'
  import { Person } from '@/ts/models/person'
  import { PersonState } from '@/ts/states/person/personState'
  import { PoliceReportsState } from '@/ts/states/toolbox/children/policeReportsState'
  import { PoliceReportStatus } from '@/ts/enums/policeReportStatus'
  import { Routes } from '@/ts/constants/routes'
  import { SearchState } from '@/ts/states/search/searchState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import HeadPanel from '@/components/panels/HeadPanel.vue'
  import SearchFooter from '@/components/search/SearchFooter.vue'
  import SearchResults from '@/components/search/SearchResults.vue'
  import ToolboxHeadPanel from './components/ToolboxHeadPanel.vue'
  import ToolboxSearchForm from './components/ToolboxSearchForm.vue'
  

  @Component({
    components: {
      HeadPanel,
      SearchFooter,
      SearchResults,
      ToolboxHeadPanel,
      ToolboxSearchForm,
    }
  })
  export default class Toolbox extends Vue {

    /** PRIVATE PROPERTIES */

    protected queryParams!: QueryParams
    protected returnRoute?: Route
    protected state = new SearchState(this.$store)

    private authState = new AuthState(this.$store)
    private policeReportsState = new PoliceReportsState(this.$store)

    /** LIFECYCLE  */

    public created() {
      this.returnRoute = this.state.returnRoute
    }

    public async mounted() {
      if (this.state.hasState) {
        Vue.nextTick(() => {
          window.scrollTo({ left: 0, top: this.state.scrollPosition, behavior: 'auto' })
        })
      }
    }


    /** OBSERVERS */

    @Watch('state.persons.queryParams', { immediate: true, deep: true })
    protected onQueryParamsChanged(queryParams: QueryParams) {
      this.queryParams = queryParams
    }


    /** COMPUTED PROPERTIES */

    protected get canViewPerson() {
      return this.authState.user.hasPermission(Claims.PROFILE, Crud.READ)
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get selectedPersonIds() {
      return this.state.persons.selected.ids
    }

    protected get showPanel() {
      return this.state.hasState
    }

    protected get status() {
      return this.state.persons.status
    }


    /** EVENTS */

    protected onArchived() {
      this.policeReportsState.mode = PoliceReportStatus.ARCHIVED
      this.policeReportsState.pushRoute(new Route(Routes.TOOLBOX_SEARCH, null, 'toolbox'))
      this.$router.push({ name: Routes.POLICE_REPORTS })
    }


    protected onClick(person: Person) {
      if (this.canViewPerson) {
        this.state.scrollPosition = window.scrollY

        const personState = new PersonState(this.$store, person)
        personState.pushRoute(new Route(Routes.TOOLBOX, null, 'toolbox'))

        this.$router.push({ name: Routes.PERSON_PROFILE, params: { personId: person.id.toString() } })
      }
    }
  }

