
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component

  export default class BInputRow extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: false })
    public disabled!: boolean

    @Prop()
    public label!: string

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop()
    public value!: Date

    @Prop({ default: '100px' })
    public width!: string

    @Prop({ default: '' })
    public validationMessage!: string


    /** PRIVATE PROPERTIES */

    protected date = DateFormatter.format(this.value, DateStyle.Picker)

    /** OBSERVERS */

    @Watch('value', { immediate: false })
    protected onDateChanged(date: Date) {
      this.date = DateFormatter.format(date, DateStyle.Picker)
    }

    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isVisible() {
      return (!this.disabled) ? true : this.value != null
    }

    /** EVENTS */

    protected onInput(event: InputEvent) {
      this.date = (event.target! as any).value
      const date = DateFormatter.date(this.date, DateStyle.Picker)
      this.$emit('input', date)
    }
  }
 
