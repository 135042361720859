
    import { Component, Prop, Vue} from 'vue-property-decorator'
    import { PoliceReportsState } from '@/ts/states/toolbox/children/policeReportsState'
    import { PoliceReportStatus } from '@/ts/enums/policeReportStatus'
    import { ViewStatus } from '@/ts/enums/viewStatus'
    import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
    import { EventLog } from '@/ts/models/eventLog'
    import { Person } from '@/ts/models/person'
    import ImageView from '@/components/images/ImageView.vue'
    import VueHtml2pdf from 'vue-html2pdf'
    import DenyForm from '@/components/forms/DenyForm.vue'

    @Component({
      components: {
       ImageView,
       VueHtml2pdf,
       DenyForm
    }
    })
    export default class PoliceReportPdf extends Vue {


      /** PUBLIC PROPERTIES */
      public $refs!: {
        vue: Vue
        element: HTMLInputElement

        vues: Vue[]
        elements: HTMLInputElement[]

        html2Pdf: HTMLInputElement
      }
 
      @Prop()
      public state!: PoliceReportsState
      public isDeny = false
      public person = new Person()
      public event = new EventLog()
      
    
      /** PRIVATE PROPERTIES */
      protected showModal = false

      /** LIFECYCLE */

      public beforeUpdate() {
        this.person = new Person(this.policeReport?.event?.patron?.person)
        this.event = new EventLog(this.policeReport?.event)
      }

      /** OBSERVERS */

      /** COMPUTED PROPERTIES **/

      protected get isFailed() {
        return this.state.status == ViewStatus.FAILED
      }

      protected get isArchived() {
        return (this.state.mode == PoliceReportStatus.ARCHIVED) ? false : true
      }

      protected get createdDate() {     
        return DateFormatter.format(this.policeReport.createdDate, DateStyle.Medium)
      }

      protected get eventDate() {
        return DateFormatter.format(this.policeReport?.event.createdDate, DateStyle.Short)
      }

      protected get eventTime() {
        return this.policeReport.event.createdTime ?? ''
      }

      protected get policeReport() {
        return this.state.policeReport
      }

      protected get terminalLocation() {
        return this.event.terminalLocation?.name ?? ''
      }

      protected get terminalStation() {
        return this.event.terminalStation?.name ?? ''
      }

      protected get status() {
        return this.state.status
      }

      protected get submitMessage() {    
        return (this.status == ViewStatus.IN_PROGRESS && !this.isDeny) ? 'Approving...' : 'Approve request'
      }

      protected get denyMessage() {
        return (this.status == ViewStatus.IN_PROGRESS && this.isDeny) ? 'Denying...' : 'Deny request'
      }

      /** EVENTS */

      protected onCancel() {
        this.state.status = ViewStatus.NONE
        this.showModal = false
        this.clear()
      }

      protected async onReject() {     
        this.$bvModal.show('add-reason')
      }

      protected async onDeny(comment: string) {
        this.showModal = true       
        this.isDeny = true
        this.state.status = ViewStatus.IN_PROGRESS
  
        await this.state.rejectPoliceReport(this.policeReport.id, comment)
        await this.finish()
        this.onCancel()

      }

      protected async onSubmit() {
        this.state.status = ViewStatus.IN_PROGRESS
        await this.pdfGeneration()        
      }

      protected async pdfGeneration() {
        //prepare html for generating pdf file
        const el = document.getElementById('add-pdf-content')
        if (el != undefined) { el.innerHTML = '' }       
        const pdfMain = document.getElementById('pdf-main-content')
        const cloneNode = pdfMain?.cloneNode(true)
        el!.append(cloneNode!)

        //generate pdf file
        const pdfExtension = this.$refs.html2Pdf as any
        pdfExtension.generatePdf()
      }

      protected async hasDownloaded(blobPdf: any) {
        await this.state.approvePoliceReport(this.policeReport.id, blobPdf)
        await this.finish()
        this.onCancel()
      }

      protected async finish() {
        this.state.fetch(this.state.queryParams)
      }

     protected async clear() {
       this.isDeny = false
      }

    }

  