
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { IJsonIdentity } from '@/ts/interfaces/jsonIdentity'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import MultiSelect from '@/components/multiselect/MultiSelect.vue'

  @Component({
    components: { MultiSelect }
  })
  export default class BMergeSelectRow extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: false })
    public checked!: boolean

    @Prop({ default: '' })
    public label!: string

    @Prop({ default: new Array<IJsonIdentity>() })
    public options!: IJsonIdentity[]

    @Prop()
    public initialValue!: IJsonIdentity

    @Prop()
    public resetValue!: IJsonIdentity

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop()
    public textLabel!: string

    @Prop()
    public value!: any

    @Prop({ default: '150px' })
    public width!: string


    /** PRIVATE PROPERTIES */

    protected isChecked = false
    protected inputValue = this.initialValue


    /** OBSERVERS */

    @Watch('checked', { immediate: true})
    protected onCheckedChanged(checked: boolean) {
      this.isChecked = checked
    }

    @Watch('resetValue', { immediate: false, deep: false })
    protected onResetValueChanged(value: IJsonIdentity, oldValue: IJsonIdentity) {
      this.isChecked = false

      if (value.id != oldValue.id) {
        this.inputValue = value
      }
    }

    @Watch('isChecked')
    protected onIsCheckedChanged(checked: boolean) {
      this.$emit('input', (checked) ? this.inputValue : this.resetValue)
    }

    @Watch('status')
    protected onStatusChanged(status: ViewStatus) {
      if (status == ViewStatus.IN_PROGRESS) {
        this.isChecked = false
      }
    }

    
    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    /** EVENTS */

    protected onInput(value: IJsonIdentity) {
      this.inputValue = value
      if (this.isChecked) {
        this.$emit('input', value)
      }
    }
  }
 
