
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { EventLog } from '@/ts/models/eventLog'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import EventCodes from '@/components/eventcodes/EventCodes.vue'

  @Component({
    components: {
      EventCodes
    }
  })
  export default class MergeEvents extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop({ default: new Array <EventLog>() })
    public eventLogs!: EventLog[]

    @Prop()
    public state!: ToolboxState




    /** COMPUTED PROPERTIES */


    protected get isLoading() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }
  }
  

