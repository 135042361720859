
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Ban } from '@/ts/models/ban'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { LookupState } from '@/ts/states/lookup/lookupState'
  import { Media } from '@/ts/models/media'
  import { Note } from '@/ts/models/note'
  import { PersonState } from '@/ts/states/person/personState'
  import { TerminalComment } from '@/ts/models/terminalComment'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import BackToLink from '@/components/links/BackToLink.vue'
  import BanPanel from '@/components/panels/BanPanel.vue'
  import BDateRow from '@/components/tables/BDateRow.vue'
  import BHashRow from '@/components/tables/BHashRow.vue'
  import BInputRow from '@/components/tables/BInputRow.vue'
  import BSelectRow from '@/components/tables/BSelectRow.vue'
  import BanForm from '@/components/forms/BanForm.vue'
  import FingerPin from '@/components/badges/FingerPin.vue'
  import ImageView from '@/components/images/ImageView.vue'
  import MultiSelect from '@/components/multiselect/MultiSelect.vue'
  import NoteForm from '@/components/forms/NoteForm.vue'
  import StatusKey from '@/components/legends/StatusKey.vue'
  import TerminalCommentForm from '@/components/forms/TerminalCommentForm.vue'


  @Component({
    components: {
      BackToLink,
      BanForm,
      BanPanel,
      BDateRow,
      BHashRow,
      BInputRow,
      BSelectRow,
      FingerPin,
      ImageView,
      MultiSelect,
      NoteForm,
      StatusKey,
      TerminalCommentForm
    }
  })
  export default class PersonDetails extends Vue {

    /** PROPERTIES */

    @Prop({ default: Crud.UPDATE })
    public mode!: Crud

    @Prop({ default: Crud.UPDATE })
    public imageMode!: Crud

    @Prop()
    public state!: PersonState

   
    /** PRIVATE PROPERTIES */

    protected authState = new AuthState(this.$store)
    protected lookupState = new LookupState(this.$store)
    protected note = new Note()
    protected rotation = 0
    protected terminalComment = new TerminalComment()
    
    /** OBSERVERS */

    @Watch('state.showIdQuery')
    protected onShowIdQueryChanged(show: boolean) {
      if (show) {
        this.state.terminalComments.setTerminalComment(new TerminalComment())
        this.$bvModal.show('id-query-terminal-comment')
      }
    }

    @Watch('person.proofOfId.number')
    protected onProofOfIdNumberChanged(number: string) {
      if (number) {
        this.state.validateProofOfId()
      }
    }

    /** COMPUTED PROPERTIES */

    protected get age() {
      return (this.person.age > 0) ? `${this.person.age} years old` : ''
    }

    protected get canCreateNotes() {
      return this.user.hasPermission(Claims.PROFILE_NOTES, Crud.CREATE)
    }

    protected get canCreateTerminalComments() {
      return this.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.CREATE)
    }

    protected get canReadBans() {
      return this.user.hasPermission(Claims.PROFILE_BANS, Crud.READ)
    }

    protected get canReadNotes() {
      return this.user.hasPermission(Claims.PROFILE_NOTES, Crud.READ)
    }

    protected get canReadTerminalComments() {
      return this.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.READ)
    }

    protected get canUpdateBans() {
      return this.user.hasPermission(Claims.PROFILE_BANS, Crud.UPDATE)
    }

    protected get disabled() {
      return this.mode == Crud.READ
    }

    protected get isFailed() {
      return this.status == ViewStatus.FAILED ? 5 : 0
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUnder18() {
      return this.person.age < 18
    }

    protected get isValidDob() {
      return this.person.isValidDob ? '' : 'Date of birth cannot be in the future'
    }

    protected get isValidEmail() {
      return this.person.isValidEmail ? '' : 'Please enter a valid email address'
    }

    protected get isVisible() {
      return this.status > ViewStatus.IN_PROGRESS
    }

    protected get person() {
      return this.state.person.edit
    }

    protected get status() {
      return this.state.status
    }

    protected get user() {
      return this.authState.user
    }

    /** EVENTS */

    protected onAddNote() {
      this.state.notes.reset()
    }

    protected onAddTerminalComment() {
      this.state.terminalComments.reset()
    }

    protected onEditBan(ban: Ban) {
      if (!ban.isExpired) {
        this.state.bans.setBan(ban)
        this.$bvModal.show('edit-ban')
      }
    }

    protected onDeletePhoto() {
      this.person.proofOfId.photo = new Media()
      this.person.proofOfId.scan = new Media()
    }

    protected onDeleteFinger() {
      this.person.hasFingerPin = false
    }

    protected onHidden() {
      this.state.showIdQuery = false
      this.$emit('query')
    }
  }

