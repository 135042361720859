
  import { Component, Prop, Watch, Vue, VModel } from 'vue-property-decorator'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component
  export default class BMergedRow extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: '' })
    public color!: string

    @Prop({ default: '' })
    public initialValue!: string

    @Prop({ default: '' })
    public label!: string

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop({ default: '150px' })
    public width!: string

    @Prop({ default: '' })
    public validationMessage!: string

    @VModel()
    public model!: string


    /** PRIVATE PROPERTIES */

    protected isChecked = false


    /** OBSERVERS */

    @Watch('status')
    protected onStatusChanged(status: ViewStatus) {
      if (status == ViewStatus.IN_PROGRESS) {
        this.isChecked = false
      }
    }

    /** COMPUTED PROPERTIES */

    protected get textColor() {
      return (this.isDirty) ? this.color : '#000000'
    }

    protected get isDirty() {
      return this.initialValue != this.model
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

  }
 
