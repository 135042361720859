

  import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
  import { IJsonIdentity } from '@/ts/interfaces/jsonIdentity'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  import MultiSelect from '@/components/multiselect/MultiSelect.vue'

  @Component({
    components: { MultiSelect }
  })
  export default class BSelectRow extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: false })
    public disabled!: boolean

    @Prop()
    public label!: string

    @Prop({ default: new Array<any>() })
    public options!: any[]

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop()
    public textLabel!: string

    @Prop({ default: '100px' })
    public width!: string


    @VModel()
    protected model!: IJsonIdentity

  /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

  }

