
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

  import { Crud } from '@/ts/enums/crud'
  import { Person } from '@/ts/models/person'
  import { PersonStatusType } from '@/ts/enums/personStatusType'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  //import { EventBus } from '@/ts/event-bus'
  import BMergedRow from '@/components/tables/BMergedRow.vue'
  import FingerPin from '@/components/badges/FingerPin.vue'
  import ImageView from '@/components/images/ImageView.vue'
  import PersonProfileView from '@/components/person/PersonProfileView.vue'

  import _ from 'lodash'

  @Component({
    components: {
      BMergedRow,
      FingerPin,
      ImageView,
      PersonProfileView
    }
  })
  export default class MergedDetails extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: ToolboxState

   //Prop()
   // public personState!: PersonState

    /** PRIVATE PROPERTIES */

    protected color = '#7F52AD'
    protected eventMode = Crud.NONE
    protected imageMode = Crud.NONE
    protected initial!: Person
    protected isBanned = false
    protected isSaving = false
    protected isVerified = false

    /** OBSERVERS */

    @Watch('original', { immediate: true, deep: false })
    protected onOriginalChanged(original: Person) {
      this.color = (original.isBanned) ? '#D4285D' : (original.is(PersonStatusType.VERIFIED)) ? '#0FA39C' : '#7F52AD'
      this.initial = original.copy()
      this.isBanned = original.isBanned
      this.isVerified = (!original.isBanned && original.is(PersonStatusType.VERIFIED))
  
    }

  //  public mounted() {
    //  EventBus.$on('cropped', this.onImgEdit)

   // }


    /** COMPUTED PROPERTIES */

    protected get original() {
      return this.state.verification.original
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get name() {
      return this.original.fullname
    }

    protected get status() {
      return this.state.status
    }

    protected get isValidDob() {
      return this.original.isValidDob ? '' : 'Date of birth cannot be in the future'
    }

    protected get isValidEmail() {
      return this.original.isValidEmail ? '' : 'Please enter a valid email address'
    }


    
    /** EVENTS */

    protected onCancel() {
      this.$bvModal.hide('view-person')
    }

    protected onIdQuery() {
      this.state.createIdQuery()
    }

    protected async onSave() {
      try {
        this.isSaving = true
        await this.state.person.save()
    
        this.state.mergeState.update(this.state.person.person.original)
        this.isSaving = false
        this.onCancel()
      }
      catch (error) {
        this.isSaving = false
        console.log(error)
      }
    }


    protected onSwap() {
      this.$emit('swap')
    }

    protected onView() {
 
      this.state.person.log(this.original)
      this.state.person.setPerson(this.original)
      this.state.person.getMetadata()
      this.$bvModal.show('view-person')
    }
  }

