
  import { Component, Watch, Vue } from 'vue-property-decorator'

  import { IPerson, Person } from '@/ts/models/person'
  import { PersonStatusType } from '@/ts/enums/personStatusType'
  import { Route, RouteParam } from '@/ts/models/route'
  import { Routes } from '@/ts/constants/routes'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { VerificationType } from '@/ts/enums/verificationType'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import { Crud } from '@/ts/enums/crud'
  import DuplicateToast from '@/components/toasts/DuplicateToast.vue'
  import HeadPanel from '@/components/panels/HeadPanel.vue'
  import MergeDetails from '@/components/toolbox/merge/MergeDetails.vue'
  import PersonProfileView from '@/components/person/PersonProfileView.vue'
  import ProgressView from '@/components/progress/ProgressView.vue'
  import ToolboxFooter from '@/components/toolbox/ToolboxFooter.vue'
  import VerifyComplete from './components/VerifyComplete.vue'
  import VerifySearchForm from './components/VerifySearchForm.vue'

  import { Media } from '@/ts/models/media'

  @Component({
    components: {
      DuplicateToast,
      HeadPanel,
      MergeDetails,
      PersonProfileView,
      ProgressView,
      ToolboxFooter,
      VerifyComplete,
      VerifySearchForm
    }
  })
  export default class Verify extends Vue {

    /** ROUTE PARAMS */

    public personId = 0

    /** PRIVATE PROPERTIES */

    protected eventMode = Crud.NONE
    protected returnRoute?: Route | null
    protected showFilter = false
    private state = new ToolboxState(this.$store)

    protected disableSubmit = true

    public media!: Media
    
    /** LIFECYCLE */

    public created() {
      this.returnRoute = this.state.popRoute()
      this.state.update()
    }
 
    /** OBSERVERS */

    @Watch('hasState', { immediate: false })
    protected onHasStateChanged(hasState: number) {    
      this.showFilter = !hasState
    }

    @Watch('state.person.person.edit.personStatus.id')
    protected onPersonStatusChanged(status: PersonStatusType) {
      if (status != PersonStatusType.NEW) {
        this.state.clearDuplicates()
      }
    }

    @Watch('state.person.duplicate')
    protected onDuplicateChanged(duplicate: Person) {
      this.state.duplicates = [duplicate]
    }

    /** COMPUTED PROPERTIES */

    protected get duplicates() {
      return this.state.duplicates
    }

    protected get hasState() {
      return this.state.hasState()
    }

    protected get icon() {
      return this.state.fullscreen ? 'fullscreen-exit' : 'fullscreen'
    }

    protected get isComplete() {
      if (this.isMerge) {
        return this.state.mergeState.progress == this.state.mergeState.count
      }

      return this.state.verifyState.progress == this.state.verifyState.count
    }

    protected get isFailed() {
      return this.state.status == ViewStatus.FAILED
    }

    protected get isMerge() {
      return this.verification != null && this.verification.typeId == VerificationType.MERGE
    }

    protected get isSuccessful() {
      return this.state.status == ViewStatus.SUCCEEDED
    }

    protected get isVerify() {
      return this.state.mode == VerificationType.VERIFY
    }

    protected get isVisible() {
      return this.state.person.status >= ViewStatus.IN_PROGRESS && this.state.person.status < ViewStatus.FAILED
    }

    protected get percent() {
      return this.state.progress / this.state.count()
    }

    protected get process() {
      return (this.isMerge) ? 'merged' : 'verified'
    }

    protected get progress() {
      return this.state.progress
    }

    protected get title() {
      return (this.state.mode == VerificationType.MERGE) ? 'Merge' : 'Verify'
    }

    protected get total() {
      return this.state.count()
    }

    protected get verification() {
      return this.state.verification
    }



    /** EVENTS */

    protected onCancel() {
      this.state.person.reset()
      if (this.returnRoute != null) {
        this.$router.push(this.returnRoute.toPath())
      }
      else {
        this.$router.push(Routes.TOOLBOX_SEARCH)
      }
    }

    protected onClick(person: Person) {
      this.state.person.setPerson(person)
      this.state.person.pushRoute(new Route(Routes.VERIFY, null))

      this.$router.push({ name: Routes.PERSON_PROFILE, params: { personId: person.id.toString(), mode: Crud.READ.toString() } })
    }

    protected onFullscreen() {
      this.state.fullscreen = !this.state.fullscreen
      if (this.state.fullscreen && this.returnRoute != null) {
        this.state.pushRoute(this.returnRoute)
      }
    }

    protected async onIdQuery() {
      await this.state.submit()
    }
  }

