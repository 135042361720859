
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { Crud } from '@/ts/enums/crud'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import DuplicateDetails from './DuplicateDetails.vue'
  import ImageView from '@/components/images/ImageView.vue'
  import MergedDetails from './MergedDetails.vue'
  import MergeEvents from './MergeEvents.vue'
//  import { EventBus } from '@/ts/event-bus'

  @Component({
    components: {
      DuplicateDetails,
      ImageView,
      MergedDetails,
      MergeEvents
    }
  })
  export default class MergeDetails extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: ToolboxState

    @Prop({ default: '' })
    public identify!: string

    
    /** PRIVATE PROPERTIES */

    protected mode = Crud.UPDATE

    /** COMPUTED PROPERTIES */

    protected get duplicate() {
      return this.verification.duplicate
    }

    protected get eventLogs() {
      return this.verification.originalEventLogs
    }

    protected get original() {
      return this.verification.original
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get status() {
      return this.state.status
    }

    protected get verification() {
      return this.state.verification
    }

   
    /** EVENTS */

    protected onCrop() {
      alert('crop')
    }

    protected onHide() {
      this.$bvModal.hide('lightbox')
    }
  }

