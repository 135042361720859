
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class ProgressView extends Vue {

    /** PUBLIC PROPERTIES */

    @Prop({ default: 0 })
    public index!: number

    @Prop({ default: 0 })
    public progress!: number

    @Prop({ default: 0 })
    public total!: number


    /** COMPUTED PROPERTIES */

    protected get percentage() {
      if (this.total <= 0) {
        return 0
      }

      if (this.progress >= this.total) {
        return 100
      }

      return (this.progress * 100).toFixed(0)
    }

  }

