
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { Person } from '@/ts/models/person'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { VerificationType } from '@/ts/enums/verificationType'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import DuplicatesTab from '@/components/carousels/tabs/DuplicatesTab.vue'
  import SelectedEventsTab from '@/components/carousels/tabs/SelectedEventsTab.vue'
  import { PersonStatusType } from '../../ts/enums/personStatusType'
  import { EventBus } from '@/ts/event-bus'

  @Component({
    components: {
      DuplicatesTab,
      SelectedEventsTab
    }
  })
  export default class ToolboxFooter extends Vue {

    /** PROPERTIES */

    @Prop({ default: false })
    public showPanel!: boolean

    @Prop({ default: false })
    public shrink!: boolean

    @Prop()
    public state!: ToolboxState



    /** PRIVATE PROPERTIES */

    protected slideDown = this.shrink
    protected slideIn = false
    protected slideOut = false
    protected hasImage = true

    /** LIFECYCLE */

    public mounted() {   
      this.state.slidesToShow = Math.floor((window.innerWidth - 72) / 140)
     
    }

    public created() {
      EventBus.$on('media-no-image', this.hasPhoto)
    }

    public beforeDestroy() {
      EventBus.$off('media-no-image')
    }

    public beforeUpdate() {
      this.state.slidesToShow = Math.floor((window.innerWidth - 72) / 140)
    }


    /** OBSERVERS */
    
    @Watch('duplicates', { immediate: true })
    protected onDuplicatesChanged(duplicates: [Person]) {
      if (duplicates != null && duplicates.length > 0) {
        this.$bvToast.show('duplicate-toast')
      }
      else {
        this.$bvToast.hide('duplicate-toast')
      }
    }

    @Watch('hasDuplicates', { immediate: false })
    protected onHasDuplicatesChanged(hasDuplicates: boolean) {
      this.slideDown = !hasDuplicates && !this.hasEvents
      this.state.tabIndex = 0

    }

    @Watch('hasEvents', { immediate: false })
    protected onHasEventsChanged(hasEvents: boolean) {
      this.slideDown = !this.hasDuplicates && !hasEvents
      this.state.tabIndex = (this.hasDuplicates && this.hasEvents) ? 1 : 0
    }


    @Watch('showPanel', { immediate: true })
    protected onShowPanelChanged(show: boolean) {
      if (show && this.slideIn == true) {
        this.slideOut = !show
      }

      this.slideIn = show
    }

    /** COMPUTED PROPERTIES */

    public hasPhoto(hasImage: boolean) {
      this.hasImage = hasImage
    }

    protected get isDisabled() {
      if (!this.hasImage) {
        return true
      }

      return this.state.status == ViewStatus.SUBMITTING
    }

    protected get canMoveNext() {
      return this.state.progress < this.state.count() - 1
    }

    protected get chevron() {
      return (this.slideDown) ? 'chevron-up' : 'chevron-down'
    }

    protected get hasDuplicates() {
      return this.state.hasDuplicates
    }

    protected get hasEvents() {
      return this.state.person.events.selected.hasState
    }

    protected get hasSelectedIds() {
      return this.state.person.events.selected.ids.length > 0
    }

    protected get hasResults() {
      return this.hasDuplicates || this.hasEvents
    }

    protected get isComplete() {
      return this.state.progress == this.state.count()
    }

    protected get isMerge() {
      return this.verification.typeId == VerificationType.MERGE
    }

    protected get status() {
      return this.state.status
    }

    protected get submitText() {
      if (this.isMerge) {
        return this.state.status == ViewStatus.SUBMITTING ? 'Merging...' : 'Merge and Next'
      }

      return this.state.status == ViewStatus.SUBMITTING ? 'Submitting...' : 'Submit and Next'
    }

    protected get verification() {
      return this.state.verification
    }


    /** EVENTS */

    protected onCancel() {
      this.state.fullscreen = false
      this.$emit('cancel')
    }

    protected onClick(person: Person) {
      this.$emit('click', person)
    }

    protected onClose() {
      this.slideDown = !this.slideDown
    }

    protected onConvertToMerge(person: Person) {
      this.state.swap(person)
    }

    protected onConvertToVerify() {
      this.state.swap()
    }

    protected async onDelete() {
      //await this.state.delete()
    }

    protected async onIdQuery() {
      await this.state.createIdQuery()
    }

    protected onSkip() {
      this.state.moveNext()
    }

    protected async onSubmit() {
      (this.state.person.person.edit.is(PersonStatusType.IDQUERY) && !this.isMerge) ? await this.onIdQuery() : await this.state.submit()
    }
  }

