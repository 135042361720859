
  import { Component, Watch, Vue } from 'vue-property-decorator'

  import { Crud } from '@/ts/enums/crud'
  import { Person } from '@/ts/models/person'
  import { PersonState } from '@/ts/states/person/personState'
  import { Route } from '@/ts/models/route'
  import { Routes } from '@/ts/constants/routes'
  import { SearchState } from '@/ts/states/search/searchState'
  import { ViewEventType } from '@/ts/enums/viewEventType'

  import HeadPanel from '@/components/panels/HeadPanel.vue'
  import PersonFooter from '@/components/person/PersonFooter.vue'
  import PersonProfileView from '@/components/person/PersonProfileView.vue'
  import ProgressArrows from '@/components/progress/ProgressArrows.vue'


  @Component({
    components: {
      HeadPanel,
      PersonFooter,
      PersonProfileView,
      ProgressArrows
    }
  })
  export default class PersonProfile extends Vue {

    /** PUBLIC ROUTE PARAMS */

    public personId = 0

  /** PRIVATE PROPERTIES */

    protected mode = Crud.UPDATE
    protected imageMode = Crud.UPDATE
    protected returnRoute?: Route
    private state = new PersonState(this.$store)
    private searchState = new SearchState(this.$store)

  /** LIFECYCLE */

    public created() {
      this.state.showOrganisation = true
      this.state.getMetadata()
      this.returnRoute = this.state.returnRoute
    }

    /** OBSERVER */

    @Watch('state.person.original', { immediate: true })
    protected onPersonChanged(person: Person) {
      this.state.log(person)
    }

    /** COMPUTED PROPERTIES */

    protected get index() {
      return this.searchState.index
    }

    protected get isSelected() {
      return this.searchState.persons.selected.ids.includes(this.personId)
    }

    protected get showPanel() {
      return this.state.hasState
    }

    protected get total() {
      return this.searchState.total
    }


    /** EVENTS */

    protected onClick(person: Person) {
      if (!this.isCurrentPerson(person.id)) {
        this.state = new PersonState(this.$store, person)
        this.state.getMetadata()

        this.$router.push({ name: Routes.PERSON_PROFILE, params: { personId: person.id.toString() } })
      }
    }

    protected async onIdQuery() {
      await this.state.save()
    }

    protected onMove(index: number) {
      const person = this.searchState.moveToPerson(index)
      this.onClick(person)
    }


  /** PRIVATE METHODS */

    private isCurrentPerson(id: number) {
      return this.personId == id
    }
  }

