
  import { Component, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { PoliceReportStatus } from '@/ts/enums/policeReportStatus'
  import { Route } from '@/ts/models/route'
  import { Routes } from '@/ts/constants/routes'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { VerificationType } from '@/ts/enums/verificationType'

  import BackToLink from '@/components/links/BackToLink.vue'
  import BoxLink from '@/components/links/BoxLink.vue'

  @Component({
    components: {
      BackToLink,
      BoxLink
    }
  })
  export default class ToolboxHeadPanel extends Vue {

    /** PRIVATE PROPERTIES */

    protected state = new ToolboxState(this.$store)
    private authState = new AuthState(this.$store)

   
    /** LIFECYCLE */

    public mounted() {
      this.state.reset()
      this.state.fetch()

      this.state.mode = VerificationType.MERGE;
      this.state.fetch()
    }


    /** COMPUTED PROPERTIES  */

    protected get canMerge() {
      return this.authState.user.hasPermission(Claims.TOOLBOX_MERGE, Crud.READ)
    }

    protected get canSubmitPoliceReports() {
      return this.authState.user.hasPermission(Claims.TOOLBOX_POLICEREPORTS, Crud.READ)
    }

    protected get canVerify() {
      return this.authState.user.hasPermission(Claims.TOOLBOX_VERIFY, Crud.READ)
    }

    protected get name() {
      return this.authState.user.givenNames
    }

    /** EVENTS */

    protected onMerge() {

      this.state.pushRoute(new Route(Routes.TOOLBOX_SEARCH, null, 'toolbox'))
      this.state.mode = VerificationType.MERGE
      if (this.state.hasState()) {
        this.$router.push({ name: Routes.VERIFY })
      }
    }

    protected onReports() {
      this.state.policeReports.mode = PoliceReportStatus.REQUESTED
      this.state.policeReports.pushRoute(new Route(Routes.TOOLBOX_SEARCH, null, 'toolbox'))
      if (this.state.policeReports.hasState) {
        this.$router.push({ name: Routes.POLICE_REPORTS })
      }
    }

    protected onVerify() {
      this.state.pushRoute(new Route(Routes.TOOLBOX_SEARCH, null, 'toolbox'))
      this.state.mode = VerificationType.VERIFY

      if (this.state.hasState()) {
        const person = this.state.verifications[0].original
        this.state.person.setPerson(person)

        this.$router.push({ name: Routes.VERIFY })
      }
    }
  }
  
