
  import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator'
  import { ViewStatus } from '@/ts/enums/viewStatus'
import { Md5 } from 'md5-typescript'

  @Component

  export default class BHashRow extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: false })
    public disabled!: boolean

    @Prop()
    public label!: string

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop({ default: '100px' })
    public width!: string


    @VModel({ type: String })
    public model!: string


    /** PRIVATE PROPERTIES */

    protected decryptedValue = ''
    protected edit = false



    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }


    /** OBSERVERS */

    @Watch('disabled')
    protected onDisabledChanged() {
      this.onCancel()
    }


    /** EVENTS */

    protected onCancel() {
      this.decryptedValue = ''
      this.edit = false
    }

    protected onChange() {
      if (this.decryptedValue != null && this.decryptedValue.length > 0) {
        this.model = Md5.init(this.decryptedValue)
      }
      else {
        this.model = ''
      }

      this.edit = false

      this.$emit('change')
    }
  }
 
