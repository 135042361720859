
  import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component

  export default class BInputRow extends Vue {

  /** PUBLIC PROPERTIES */

    @Prop({ default: false })
    public disabled!: boolean

    @Prop()
    public label!: string

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop({ default: '100px' })
    public width!: string

    @Prop({ default: '' })
    public validationMessage!: string

    @VModel({ type: String })
    public model!: string


    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }


    /** PRIVATE METHODS */

    protected uppercased(value: string) {
      return value.toUpperCase()
    }
  }
 
